<template>
  <div>
    <el-card class="mb-32 px-16 sm:px-24 test">
      <table-component ref="table" :loading="loadingButton" @on-send="onSendEmail" />
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AdminService from '@/services/admin.service'

const TableComponent = () => import('@/components/email-editor/Table')
const ElCard = () => import('@/components/shared/ELCard')

export default {
  name: 'EmailEditor',

  components: { TableComponent, ElCard },

  data () {
    return {
      loadingButton: ''
    }
  },

  created () {
    this.loading(false)
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    onSendEmail (value) {
      console.log(value)

      this.loadingButton = value.eventName

      AdminService.sendEmails(value.data)
        .then(res => {
          console.log(res)

          this.showNotification({
            visible: true,
            type: 'success',
            message: this.$refs.table.emailsQuantity ? `${this.$refs.table.emailsQuantity} emails sent!` : 'Email sent!'
          })

          if (value.eventName === 'all') {
            this.$refs.table.resetFields()
          }
        })
        .catch(() => {
          this.showNotification({
            visible: true,
            type: 'error',
            message: 'Something went wrong!'
          })
        })
        .finally(() => {
          this.loadingButton = ''
        })
    }
  }
}
</script>
