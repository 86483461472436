import $axios from '@/core/api.config'
import { generateRequestParams } from '@/core/helper-functions'

class AdminService {
  // get list of finance orders
  getFinanceOrders () {
    return $axios.get('/admin/finance/orders')
  }

  downloadOrdersFile (params = {}) {
    return $axios.get('/admin/finance/orders/export', {
      params: generateRequestParams(params),
      responseType: 'blob'
    })
  }

  uploadOrdersFile (data) {
    return $axios.put('/admin/finance/orders/updateFromCSV/', data)
  }

  sendEmails (data) {
    return $axios.post('/admin/users/sendMailAnnouncement', data)
  }
}

export default new AdminService()
